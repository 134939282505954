#centercontent {
  float: center;
  height: 500px;
}

#bgvideo {
  width: 100%;
}

#overlayvideo1 {
  width: 17%;
  position: absolute;
  top: 13%;
  left: 30%;
}

#overlayvideo2 {
  width: 11%;
  position: absolute;
  top: 15%;
  left: 45%;
}

#overlayvideo3 {
  width: 8%;
  position: absolute;
  top: 41%;
  left: 70%;
}
#dataoverlay {
  width: 68%;
  position: absolute;
  top: 15%;
  left: 14%;
}
#footer {
  border: 6px;
  height: 80px;
  width: 120%;
  border-color: white;
  border-style: solid;
  right: -10px;
  position: absolute;
  bottom: 0;
  margin-bottom: 15px;
}

.scroll-left {
  position: relative;
  border: 8px;
  border-color: white;
}

.scroll-left p {
  padding-top: 12px;
  white-space: nowrap;
  font-size: 80px;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 50px;
  text-align: center;
  transform: translateX(100%);
  animation: scroll-left 10s linear infinite;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
