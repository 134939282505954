.InfoPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px;
}

.InfoPage .main-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  font-weight: 100;
  font-size: 16pt;
  text-align: center;
}

.InfoPage h1 {
  font-weight: 100;
  margin: 0 auto;
}

.InfoPage .intro-video {
  max-width: 250px;
  margin: 10px;
}

.InfoPage .combine-image {
  max-height: 400px;
}

.InfoPage .percent-image {
  max-height: 250px;
}

.InfoPage .statue-group-image {
  opacity: 0.8;
  height: 160px;
  width: 270px;
  margin: 0 auto;
}

.InfoPage .box_content {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.InfoPage h1 .box_content {
  font-weight: 100;
}

/* Not confirmed */

.InfoPage .container {
  margin: auto;
  font-weight: 100;
  font-size: 20pt;
}
.InfoPage .container video {
  margin: 5px;
  width: 80%;
  max-height: 140px;
}

.InfoPage .highlight {
  font-weight: bold;
}
.InfoPage p {
  margin-bottom: 4px;
}
.InfoPage svg {
  stroke: white;
}

.InfoPage svg:hover {
  stroke: white;
  stroke: var(--accent-color);
}

.InfoPage a {
  color: white;
  font-weight: 300;
  text-decoration: none;
}
