.DragonPath {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 100px;
}

.DragonPath .main-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  font-weight: 100;
  font-size: 16pt;
  text-align: center;
}

.DragonPath h1 {
  font-weight: 100;
  margin-bottom: 0;
}

.DragonPath .title {
  margin: 0;
}

.DragonPath .image {
  width: 100%;
}

.DragonPath .code {
  font-size: 10pt;
}
